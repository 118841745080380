import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBrLXaGreDVV6gnZj32zanPxGvx7fQf85A',
  authDomain: 'mybook-aprende.firebaseapp.com',
  projectId: 'mybook-aprende',
  storageBucket: 'mybook-aprende.appspot.com',
  messagingSenderId: '252192038815',
  appId: '1:252192038815:web:efcb924af73c0864f667aa',
  measurementId: 'G-QF67SYT45L',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
